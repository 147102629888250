<template>
    <div class="c-c-page mobile">
        <div class="c-c-page-content">
            <h1>{{$t('companyCulture.title')}}</h1>
            <ul class="reset">
                <li class="left">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img src="@/assets/images/company/u771.jpg"/>
                        </aspect-ratio>
                    </div>
                    <div class="desc">
                        <div class="desc-text">
                            <p>01 {{$t('companyCulture.p11')}}</p>
                            <p>{{$t('companyCulture.p12')}}</p>
                        </div>
                    </div>
                </li>
                <li class="right">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u773.jpg'])"/>
                        </aspect-ratio>
                    </div>
                    <div class="desc">
                        <div class="desc-text">
                            <p>02 {{$t('companyCulture.p21')}}</p>
                            <p>{{$t('companyCulture.p22')}}</p>
                        </div>
                    </div>
                </li>
                <li class="left">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img v-bind:src="$resources.autoTransform($resources.Image.static['u776.jpg'])"/>
                        </aspect-ratio>
                    </div>
                    <div class="desc">
                        <div class="desc-text">
                            <p>03 {{$t('companyCulture.p31')}}</p>
                            <p>{{$t('companyCulture.p32')}}</p>
                        </div>
                    </div>
                </li>
                <li class="right">
                    <!-- 保持600*400宽高比 -->
                    <div class="image">
                        <aspect-ratio ratio="66.67">
                            <img src="@/assets/images/company/u777.jpg"/>
                        </aspect-ratio>
                    </div>
                    <div class="desc">
                        <div class="desc-text">
                            <p>04 {{$t('companyCulture.p41')}}</p>
                            <p>{{$t('companyCulture.p42')}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>

  import AspectRatio from '../../components/AspectRatio';

  export default {
    components: {
      AspectRatio,
      'aspect-ratio': AspectRatio,
    },

    data() {
      return {};
    }
  }
</script>


<style lang="scss">

    .c-c-page.mobile {
        background-color: var(--white);

        img {
            width: 100%;
            height: 100%;
        }

        .c-c-page-content {
            width: 100%;
            max-width: 1128px;
            margin: 0 auto;
            padding-top: 80px;
            padding-bottom: 100px;

            @media screen and (max-width: 768px) {
                padding-top: 20px;
                padding-bottom: 30px;
            }

            h1 {
                margin: 0;
                font-size: 70px;
                line-height: 100px;
                text-align: center;

                @media screen and (max-width: 768px) {
                    font-size: 35px;
                    line-height: 80px;
                }
            }

            ul {
                margin-top: 40px;

                @media screen and (max-width: 768px) {
                    margin: 0 15px 0 15px;
                }

                li {
                    margin-top: 100px;

                    @media screen and (max-width: 768px) {
                        margin-top: 20px;
                    }

                    &:not(:first-child) {
                        margin-top: 60px;

                        @media screen and (max-width: 768px) {
                            margin-top: 0px;
                        }
                    }

                    &:nth-child(2) .desc .desc-text p:first-child {
                        transform: translateX(-50%);

                        @media screen and (max-width: 768px) {
                            transform: none;
                        }
                    }
                    &:nth-child(4) .desc .desc-text p:first-child {
                        width: 270px;
                        transform: translateX(-50%);

                        @media screen and (max-width: 768px) {
                            width: auto;
                            transform: none;
                        }
                    }

                    .image {
                        width: 50%;
                        max-width: 600px;
                        height: 100%;
                        position: relative;

                        & > * {
                            z-index: 10;
                        }
                        &:after {
                            content: '';
                            display: block;
                            width: 50%;
                            height: 50%;
                            background-color: rgba(1, 1, 1, .3);
                            position: absolute;
                            right: -25px;
                            bottom: -25px;
                            z-index: 5;
                        }
                    }

                    .desc {
                        height: 100px;
                        width: 100%;
                        position: relative;

                        @media screen and (max-width: 768px) {
                            margin-top: 30px;
                        }

                        .desc-text {
                            position: absolute;
                            left: calc(50% + 40px);

                            @media screen and (max-width: 768px) {
                                position: static;
                            }

                            p {
                                margin: 0;
                                color: var(--black);
                                font-size: 16px;
                                font-weight: bold;
                            }
                            p:first-child {
                                color: var(--blue);
                                font-size: 28px;
                                font-weight: 600;

                                @media screen and (max-width: 768px) {
                                    font-size: 24px;
                                }
                            }
                            p:nth-child(2) {
                                height: 30px;
                                line-height: 30px;
                                position: relative;
                                left: -50px;

                                @media screen and (max-width: 768px) {
                                    left: 50px;
                                }
                            }
                        }
                    }
                }
                li.right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .image:after {
                        left: -25px;
                        bottom: -25px;
                    }

                    .desc {
                        .desc-text {
                            left: calc(50% - 200px);

                            @media screen and (max-width: 768px) {
                                left: 0px;
                            }

                            p:nth-child(2) {
                                left: 50px;
                            }
                        }
                    }
                }
                li:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
        }
    }


</style>
